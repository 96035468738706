<div class="breadcrumb-section image-bg">
  <div class="overlay"></div>
  <div class="breadcrumb-content container">
    <h1>Contact Us</h1>
    <ol class="breadcrumb">
      <li><a alt="glandsb home link" (click)="changRoutes('/')">Home</a></li>
      <li class="active">Contact</li>
    </ol>
  </div>
</div>
<!-- breadcrumb-section -->

<div class="contact-form-section section-padding">
  <div class="container">
    <div class="section-title text-center">
      <div class="container">
        <h1>Contact Us</h1>
        <h2>Drop Your Message</h2>
      </div>
    </div>
    <div class="contact-info">
      <div
        id="contact-form"
        class="contact-form"
        name="contact-form"
        method="post"
        action="#"
      >
        <div class="row">
          <div class="col-sm-4">
            <div class="form-group">
              <i class="fa fa-user" aria-hidden="true"></i>
              <input
                type="text"
                class="form-control control-1"
                required="required"
                placeholder="Name"
                [(ngModel)]="name"
              />
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <i class="fa fa-th-list" aria-hidden="true"></i>
              <input
                type="text"
                class="form-control"
                required="required"
                placeholder="Phone number"
                [(ngModel)]="subject"
              />
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <i class="fa fa-envelope-o" aria-hidden="true"></i>
              <input
                type="email"
                class="form-control"
                required="required"
                placeholder="E-mail"
                [(ngModel)]="email"
              />
            </div>
          </div>

          <div class="col-sm-12">
            <div class="form-group">
              <i class="fa fa-comments" aria-hidden="true"></i>
              <textarea
                name="message"
                id="message"
                required="required"
                class="form-control"
                rows="7"
                placeholder="Message"
                [(ngModel)]="message"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="submit-button">
          <button (click)="sendMessage()" class="btn btn-primary">
            {{ labelHolder }}
          </button>
        </div>
      </div>
      <!-- contact form -->
    </div>
    <div id="map" style="height: 500px; width: 100%; margin-top: 30px"></div>
  </div>
  <!-- container -->
</div>
<!-- contact form section -->
