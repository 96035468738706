<div class="details-section section-padding">
  <div class="container">
    <div class="row">
      <div class="col-md-8 col-sm-12 service-image">
        <div id="product-carousel">
          <div class="carousel-image">
            <img
              class="img-responsive"
              src="../../assets/icons/63cf57f08f4762b73a0d6b14_AdobeStock_172840226-p-1080.webp"
              alt="Image"
            />
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12">
        <div class="details-info">
          <div class="title-btn-container">
            <h2>construction</h2>
            <div  (click)="goContactUs()"  class="contact-btn">Contact US</div>
          </div>
          <div class="col-md-8 col-sm-12 service-md-image">
            <div id="product-carousel">
              <div class="carousel-image">
                <img
                  class="img-responsive"
                  src="../../assets/icons/63cf57f08f4762b73a0d6b14_AdobeStock_172840226-p-1080.webp"
                  alt="Image"
                />
              </div>
            </div>
          </div>
          <div class="bullet-point">
            <div>
               <span class="icon">
              <img src="../../assets/icons/9111109_verified_icon.svg" alt="" srcset="">
            </span>
            </div>
            <div>
               <span class="point">Residential & Commercial Construction </span>
            <span>
              Full-service construction from planning to handover for homes, offices, and retail spaces.
            </span>
            </div>
          </div>

          <div class="bullet-point">
            <div>
               <span class="icon">
              <img src="../../assets/icons/9111109_verified_icon.svg" alt="" srcset="">
            </span>
            </div>
            <div>
               <span class="point">Renovation & Remodeling</span>
            <span>
               Transform existing spaces with customized interior and exterior updates.
            </span>
            </div>
          </div>
          <div class="bullet-point">
            <div>
               <span class="icon">
              <img src="../../assets/icons/9111109_verified_icon.svg" alt="" srcset="">
            </span>
            </div>
            <div>
               <span class="point">Structural Repairs & Reinforcement</span>
            <span>
              Restore and reinforce building structures for safety and longevity.
            </span>
            </div>
          </div>
          <div class="bullet-point">
            <div>
               <span class="icon">
              <img src="../../assets/icons/9111109_verified_icon.svg" alt="" srcset="">
            </span>
            </div>
            <div>
               <span class="point">Site Preparation & Land Development</span>
            <span>
               Precision site preparation, including clearing, leveling, and foundational work.
            </span>
            </div>
          </div>
          <div class="bullet-point">
            <div>
               <span class="icon">
              <img src="../../assets/icons/9111109_verified_icon.svg" alt="" srcset="">
            </span>
            </div>
            <div>
               <span class="point">Project Management & Supervision</span>
            <span>
               Comprehensive oversight to meet timelines, budgets, and quality standards.
            </span>
            </div>
          </div>
          <div class="bullet-point">
            <div>
               <span class="icon">
              <img src="../../assets/icons/9111109_verified_icon.svg" alt="" srcset="">
            </span>
            </div>
            <div>
               <span class="point">Custom Home Building</span>
            <span>
              Tailored home construction, bringing your unique vision to life.
            </span>
            </div>
          </div>
         

        </div>
      </div>
    </div>
  </div>
</div>
