<div class="testimonial-section image-bg section-padding">
  <div class="overlay"></div>
  <div class="container">
    <div class="testimonial-slider">
      <carousel
        [itemsPerSlide]="1"
        [singleSlideOffset]="false"
        [interval]="3000"
        [startFromIndex]="0"
      >
        <slide>
          <div class="row testimonial text-center">
            <div class="col-sm-8 col-sm-offset-2">
              <div class="testimonial-image">
                <img
                  class="img-responsive"
                  src="../../assets/image/Screenshot from 2024-09-07 11-19-22.png"
                  alt="image"
                />
              </div>
              <div class="testimonial-info">
                <p>
                  We've relied on Glandsb for all our equipment rentals and land survey needs, 
                  and they have consistently exceeded our expectations with their professionalism
                   and well-maintained machines—they never disappoint
                </p>
                <h5>Rugamba Olido | CEO, Eric Consultancy</h5>
              </div>
            </div>
          </div>
        </slide>

        <slide>
          <div class="row testimonial text-center">
            <div class="col-sm-8 col-sm-offset-2">
              <div class="testimonial-image">
                <img
                  class="img-responsive"
                  src="../../assets/image/Screenshot from 2024-09-07 11-20-30.png"
                  alt="image"
                />
              </div>
              <div class="testimonial-info">
                <p>
                  Glandsb has been our trusted partner for construction equipment 
                  rentals and land survey services, always delivering exceptional results
                   with their reliable machines and knowledgeable team—we couldn’t ask for better support!
                </p>
                <h5>Jane Semouel | Vice Pricedent, KRBP</h5>
              </div>
            </div>
          </div>
        </slide>

        <slide>
          <div class="row testimonial text-center">
            <div class="col-sm-8 col-sm-offset-2">
              <div class="testimonial-image">
                <img
                  class="img-responsive"
                  src="../../assets/image/Screenshot from 2024-09-07 11-21-18.png"
                  alt="image"
                />
              </div>
              <div class="testimonial-info">
                <p>
                  Whenever we need top-quality equipment or precise land surveys, we turn to Glandsb.
                   Their attention to detail, prompt service, and commitment to our project timelines 
                   have made them indispensable—they truly go above and beyond!
                </p>
                <h5>Hall Melan | Developer, Artsell</h5>
              </div>
            </div>
          </div>
        </slide>
      </carousel>
    </div>
  </div>
  <!-- container -->
</div>

<!-- testimonial section -->
