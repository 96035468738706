import { Injectable } from '@angular/core';
import { HttpClientService } from '../../http/http-client.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ServiceApiService {
  constructor(private http: HttpClientService) {}

  getMachine(): Observable<any> {
    return this.http.get('/machine');
  }
  getRealEstate(): Observable<any> {
    return this.http.get('/realEstate');
  }
  getJobs(): Observable<any> {
    return this.http.get('/jobs');
  }
  getProjects(): Observable<any> {
    return this.http.get('/project');
  }
}
