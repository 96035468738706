import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import * as L from 'leaflet';

@Component({
  selector: 'app-contact-us',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.scss',
})
export class ContactUsComponent implements OnInit {
  constructor(private router: Router) {}
  name: string = '';
  subject: string = '';
  email: string = '';
  message: string = '';
  labelHolder: string = 'Send Now';

  // ngOnInit(): void {
  //   const map = L.map('map').setView([-1.94995, 30.05885], 13);

  //   L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
  //     attribution: '&copy; OpenStreetMap contributors'
  //   }).addTo(map);
  // }

  private map!: L.Map;

  ngOnInit(): void {
    this.initMap();
    // this.addMarker(-1.9437057 29.8805778, 'Gland standard builders oficce'); // Example house coordinates
  }

  // Initialize the Leaflet map
  private initMap(): void {
    this.map = L.map('map').setView([-1.9437057, 29.8805778], 12); // Zoom close to house

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; OpenStreetMap contributors'
    }).addTo(this.map);
  }

  // Function to add a marker (pin) with optional popup text
  private addMarker(lat: number, lng: number, popupText?: string): void {
    const houseIcon = L.icon({
      iconUrl: 'https://cdn-icons-png.flaticon.com/512/64/64113.png', // House icon URL
      iconSize: [32, 32], // Size of the icon
      iconAnchor: [16, 32], // Anchor the icon to the correct spot
    });

    const marker = L.marker([lat, lng], { icon: houseIcon }).addTo(this.map);

    if (popupText) {
      marker.bindPopup(popupText).openPopup(); // Show popup on load
    }
  }

  changRoutes(link: string) {
    this.router.navigate([link]);
  }

  wait(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async sendMessage() {
    this.labelHolder = 'SEND...';
    await this.wait(3000);
    this.name = '';
    this.subject = '';
    this.email = '';
    this.message = '';
    this.labelHolder = 'Sent';
    await this.wait(1000);
    this.labelHolder = 'Send Now';
  }
}
