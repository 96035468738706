<div class="details-section section-padding">
  <div class="container">
    <div class="row">
      <div class="col-md-8 col-sm-12 service-image">
        <div id="product-carousel">
          <div class="carousel-image">
            <img
              class="img-responsive"
              src="../../assets/image/Screenshot from 2024-09-07 11-44-02.png"
              
              alt="Image"
            />
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12">
        <div class="details-info">
          <div class="title-btn-container">
            <h2>Land Survey</h2>
            <div (click)="goContactUs()" class="contact-btn">Contact US</div>
          </div>
          <div class="col-md-8 col-sm-12 service-md-image">
            <div id="product-carousel">
              <div class="carousel-image">
                <img
                  class="img-responsive"
                  src="../../assets/image/Screenshot from 2024-09-07 11-44-02.png"
                  alt="Image"
                />
              </div>
            </div>
          </div>
          <div class="bullet-point">
            <div>
               <span class="icon">
              <img src="../../assets/icons/9111109_verified_icon.svg" alt="" srcset="">
            </span>
            </div>
            <div>
               <span class="point">Boundary & Property Surveys</span>
            <span>
                Precisely define property boundaries for accurate and legal land use.
            </span>
            </div>
          </div>

          <div class="bullet-point">
            <div>
               <span class="icon">
              <img src="../../assets/icons/9111109_verified_icon.svg" alt="" srcset="">
            </span>
            </div>
            <div>
               <span class="point">Construction Stakeout</span>
            <span>
               Ensure precise structure placement based on engineering designs.
            </span>
            </div>
          </div>

          <div class="bullet-point">
            <div>
               <span class="icon">
              <img src="../../assets/icons/9111109_verified_icon.svg" alt="" srcset="">
            </span>
            </div>
            <div>
               <span class="point">Topographic Mapping</span>
            <span>
              Capture surface details for informed site planning and design.
            </span>
            </div>
          </div>

          <div class="bullet-point">
            <div>
               <span class="icon">
              <img src="../../assets/icons/9111109_verified_icon.svg" alt="" srcset="">
            </span>
            </div>
            <div>
               <span class="point"> Subdivision Surveys</span>
            <span>
               Divide large parcels into smaller, development-ready plots.
            </span>
            </div>
          </div>
          <div class="bullet-point">
            <div>
               <span class="icon">
              <img src="../../assets/icons/9111109_verified_icon.svg" alt="" srcset="">
            </span>
            </div>
            <div>
               <span class="point">As-Built Surveys</span>
            <span>
                Verify completed structures match design plans for accurate records.
            </span>
            </div>
          </div>

          <div class="bullet-point">
            <div>
               <span class="icon">
              <img src="../../assets/icons/9111109_verified_icon.svg" alt="" srcset="">
            </span>
            </div>
            <div>
               <span class="point">Land Feasibility Analysis </span>
            <span>
              Assess land suitability for various development projects.
            </span>
            </div>
          </div>

          <div class="bullet-point">
            <div>
               <span class="icon">
              <img src="../../assets/icons/9111109_verified_icon.svg" alt="" srcset="">
            </span>
            </div>
            <div>
               <span class="point">Volumetric Surveys</span>
            <span>
               Measure earthwork volumes for large-scale construction projects.
            </span>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
