import { Routes } from '@angular/router';
import { HomeComponent } from '../routes/home/home.component';
import { ContactUsComponent } from '../routes/contact-us/contact-us.component';
import { BlogComponent } from '../routes/blog/blog.component';
import { ProjectsComponent } from '../routes/projects/projects.component';
import { ProjectDetailsComponent } from '../routes/project-details/project-details.component';
import { ServiceComponent } from '../routes/services/service.component';
import { AboutComponent } from '../routes/about/about.component';
import { ServiceDetailsComponent } from '../routes/service-details/service-details.component';
import { ServiceContactUsComponent } from '../routes/service-contact-us/service-contact-us.component';
import { JobApplyComponent } from '../routes/job-apply/job-apply.component';
import { BlogDetailsComponent } from '../routes/blog-details/blog-details.component';
import { BlogDetailsComponentO } from '../routes/blog-details1/blog-details.component';
import { BlogDetailsComponentT } from '../routes/blog-details2/blog-details.component';

export const appRoutes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'contactus',
    component: ContactUsComponent,
  },
  {
    path: 'project',
    component: ProjectsComponent,
  },
  {
    path: 'project/:id',
    component: ProjectDetailsComponent,
  },
  {
    path: 'blog',
    component: BlogComponent,
  },
  {
    path: 'blog/66f91c58ea7ffe08cb1bcea0',
    component: BlogDetailsComponent,
  },
  {
    path: 'blog/66f91c58ea7ffe08cb1bcea1',
    component: BlogDetailsComponentO,
  },
  {
    path: 'blog/66f91c58ea7ffe08cb1bcea3',
    component: BlogDetailsComponentT,
  },
  {
    path: 'service/:servicetype',
    component: ServiceComponent,
  },
  {
    path: 'service/:servicetype/:id',
    component: ServiceDetailsComponent,
  },
  {
    path: 'contactus/:servicetype/:id',
    component: ServiceContactUsComponent,
  },
  {
    path: 'about',
    component: AboutComponent,
  },
  {
    path: 'job-apply/:id',
    component: JobApplyComponent,
  },
];
