import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { SharedModule } from '../../../shared/shared.module';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  constructor(private router: Router) {}

  stickHeader: boolean = false;
  sidebarVisible1: boolean = false;

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    if (scrollTop >= 180) {
      this.stickHeader = true;
    } else {
      this.stickHeader = false;
    }
  }

  changRoutes(link: string) {
    this.router.navigate([link]);
    this.closeSidebar()
  }
  closeSidebar(){
    this.sidebarVisible1= false
  }

  checkActive(url: string) {
    if (url === '/' + this.router.url.split('/')[1]) {
      return 'active';
    } else {
      return '';
    }
  }
}
