import { Environment } from './environment.types';

export var environment: Environment = {
  production: false,
  appName: 'glandsb',
  apiUrl: 'https://glandsb-cc0748e71cd2.herokuapp.com',
  // apiUrl: 'http://localhost:4000',
  uniqueIdentifier: 'MDP-DEV',
  userIdleTimeout: 120,
  language: 'en',
  contactDetails: {
    companyName: 'Docteum AB',
    companyNumber: '559115-4033',
    phone: '010-179 87 00',
    mobilDoktornPageUrl: 'dd',
    emailAddress: 'ddddd',
    facebookUrl: 'https://www.facebook.com/',
    instagramUrl: 'https://www.instagram.com/',
    twitterUrl: 'https://twitter.com',
  },
};
