import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-blog-details',
  standalone: true,
  imports: [],
  templateUrl: './blog-details.component.html',
  styleUrl: './blog-details.component.scss',
})
export class BlogDetailsComponentO {
  constructor(private router: Router) {}

  changRoutes(link: string) {
    this.router.navigate([link]);
  }
}
