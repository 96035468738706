import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-land-survey',
  standalone: true,
  imports: [],
  templateUrl: './landSurvey.component.html',
  styleUrl: './landSurvey.component.scss',
})
export class LandSurveyComponent {
  constructor(private router: Router) {}
  goContactUs() {
    this.router.navigate(['/contactus']);
  }
}
