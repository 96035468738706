import { Component } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent {
  constructor(private router: Router) {}
  email: string = '';
  label: string = 'Subscribe';

  changeRoute(link: string) {
    console.log('hello');
    this.router.navigate([link]);
  }
  wait(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async subscribe() {
    console.log('subscribe');
    console.log('===>', this.email);
    this.label = 'Subscribed';
    await this.wait(3000);
    this.email = '';
    this.label = 'Subscribe';
  }
}
