<div class="breadcrumb-section image-bg">
  <div class="overlay"></div>
  <div class="breadcrumb-content container">
    <h1>Contact Us</h1>
    <ol class="breadcrumb">
      <li><a alt="glands home link" (click)="changRoutes('/')">Home</a></li>
      <li class="active">Contact</li>
    </ol>
  </div>
</div>

<div class="contact-form-section section-padding">
  <!-- <div class="container"> -->
  <div class="section-title text-center">
    <div class="container">
      <h1>Contact Us</h1>
      <h2>Drop Your Message</h2>
    </div>
  </div>
  <div class="contact-info">
    <div class="contact-form" name="contact-form" method="post" action="#">
      <div class="formaa">
        <div class="container">
          <div class="col-sm-8">
            <div class="form-group">
              <i class="fa fa-user" aria-hidden="true"></i>
              <input
                type="text"
                class="form-control"
                required="required"
                placeholder="Name"
                [(ngModel)]="name"
              />
            </div>
          </div>
          <div class="col-sm-8">
            <div class="form-group">
              <i class="fa fa-th-list" aria-hidden="true"></i>
              <input
                type="text"
                class="form-control"
                required="required"
                placeholder="Phone number"
                [(ngModel)]="subject"
              />
            </div>
          </div>
          <div class="col-sm-8">
            <div class="form-group">
              <i class="fa fa-envelope-o" aria-hidden="true"></i>
              <input
                type="email"
                class="form-control"
                required="required"
                placeholder="E-mail"
                [(ngModel)]="email"
              />
            </div>
          </div>
          <div *ngIf="fileName !== ''" class="col-sm-8">
            <div class="form-group">
              <div class="input-holder">
                <div class="left-part">
                  <div class="file-icon">
                    <img src="../../assets/icons/file.png" alt="" />
                  </div>
                  <div>{{ fileName }}</div>
                </div>

                <div (click)="deleteFile()" class="close-cont">
                  <img src="../../assets/icons/close.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="fileName === ''" class="col-sm-8">
            <div class="form-group file-upload-container">
              <div class="details-container">
                <div>
                  <img src="../../assets/icons/upload.svg" alt="" srcset="" />
                </div>
                <div>Drop resume here or browser</div>
              </div>

              <input
                (change)="onFileSelected($event)"
                type="file"
                class="col-sm-12"
                required="required"
              />
            </div>
          </div>

          <div class="col-sm-8">
            <div class="form-group">
              <i class="fa fa-comments" aria-hidden="true"></i>
              <textarea
                name="message"
                id="message"
                required="required"
                class="form-control"
                rows="7"
                placeholder="Message"
                [(ngModel)]="message"
              ></textarea>
            </div>
          </div>
          <div class="submit-button col-sm-8">
            <button (click)="sendMessage()" class="btn btn-primary">
              {{ labelHolder }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</div>
