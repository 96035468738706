<div class="work-process image-bg section-padding">
  <div class="overlay"></div>
  <div class="process-section">
    <div class="about-container">
      <div class="">
        <div class="work-proces">
          <div class="process-info">
            <div class="about-title-title">
              <div class="company-start">
                <div class="timeLine"></div>
                KGL. 2017
              </div>
              <h2>Our insipiration is building your future</h2>
            </div>
            <p>
              Inspired by the vital role glands play in the human body as
              regulators of essential functions, we are dedicated to achieving
              precision and excellence in every project. Just as these organs
              maintain balance and harmony, we ensure meticulous care and
              unwavering integrity in our work.
            </p>
            <div routerLink="/about" class="about-btn">ABOUT US</div>
          </div>
        </div>
      </div>
      <div class="about-img">
        <img
          class="img-responsive lg-xl-image"
          src="../../assets/icons/Screenshot from 2024-10-29 05-08-01.png"
          alt="image"
        />
        <div class="sm-md-image"></div>
      </div>
    </div>
  </div>
</div>
