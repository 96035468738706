import { Component, OnInit } from '@angular/core';
import { TestimonialComponent } from '../../components/testimonial/testimonial.component';
import { ProjectsApiService } from './projects-api.service';
import { SharedModule } from '../../shared/shared.module';

export interface Property {
  image: string;
  location: string;
  info: string;
  price: string;
}

@Component({
  selector: 'app-projects',
  standalone: true,
  imports: [TestimonialComponent, SharedModule],
  templateUrl: './projects.component.html',
  styleUrl: './projects.component.scss',
})
export class ProjectsComponent implements OnInit {
  constructor(private readonly PojectsApi: ProjectsApiService) {}
  projects: Array<any> = [];
  isVisible = true; // Controls modal visibility

  ngOnInit(): void {
    console.log('hello');
    this.PojectsApi.getProjects().subscribe((res) => {
      console.log(res.data);
      this.projects = res.data;
    });
  }

  close() {
    this.isVisible = true;
  }
}
