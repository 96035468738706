import { Injectable } from '@angular/core';
import { HttpClientService } from '../../http/http-client.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ServiceMachineApi {
  constructor(private http: HttpClientService) {}

  getMachineById(id: string): Observable<any> {
    return this.http.get(`/machine/${id}`);
  }
}
