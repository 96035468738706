<div class="breadcrumb-section image-bg">
  <div class="overlay"></div>
  <div class="breadcrumb-content container">
    <h1>Service Details</h1>
    <ol class="breadcrumb">
      <li><a alt="glandsb home link">Home</a></li>
      <li class="active">Service Details</li>
    </ol>
  </div>
</div>
<!-- breadcrumb-section -->

<div class="details-section section-padding">
  <div class="container">
    <div class="header-info">
      <div class="location-info">
        <h4>
          For Sale: 4-Bedroom Home with Annexes and Ample Parking in Nyarugenge
        </h4>
      </div>
      <div class="dealType">FRw 97 500 000</div>
    </div>
    <div class="row">
      <div class="col-md-8 col-sm-12">
        <div>
          <div class="carousel-image">
            <img class="img-responsive" [src]="bigImage" alt="Image" />
          </div>
          <div class="sm-image">
            <div>
              <img
                class="img-responsive"
                src="../../assets/image/service/infront.jpeg"
                alt="Image"
                (click)="
                  changeBigImage('../../assets/image/service/infront.jpeg')
                "
              />
            </div>
            <div>
              <img
                class="img-responsive"
                src="../../assets/image/service/front.jpeg"
                (click)="
                  changeBigImage('../../assets/image/service/front.jpeg')
                "
                alt="Image"
              />
            </div>
            <div>
              <img
                class="img-responsive"
                src="../../assets/image/service/houseside.jpeg"
                (click)="
                  changeBigImage('../../assets/image/service/houseside.jpeg')
                "
                alt="Image"
              />
            </div>
            <div>
              <img
                class="img-responsive"
                src="../../assets/image/service/outdoor.jpeg"
                (click)="
                  changeBigImage('../../assets/image/service/outdoor.jpeg')
                "
                alt="Image"
              />
            </div>
          </div>
        </div>
        <div class="full-description">
          <div class="realEstateId">
            <h5>Property Location: Nyarugenge - Norvege- ruliba</h5>
          </div>
          <div class="details">
            <div class="item">
              <div class="item-title">Bedrooms</div>
              <div class="values">
                <div>
                  <img
                    src="../../assets/icons/double-bed.svg"
                    alt=""
                    srcset=""
                  />
                </div>
                <div>4</div>
              </div>
            </div>
            <div class="item">
              <div class="item-title">Bathrooms</div>
              <div class="values">
                <div>
                  <img src="../../assets/icons/shower.svg" alt="" srcset="" />
                </div>
                <div>2</div>
              </div>
            </div>
            <div class="item">
              <div class="item-title">Area</div>
              <div class="values">
                <div>
                  <img src="../../assets/icons/maximize.svg" alt="" srcset="" />
                </div>
                <div>312 sqm</div>
              </div>
            </div>
          </div>

          <div class="description-contain">
            <h5 class="descr-title">Description</h5>
            <div class="description">
              Nestled in the serene neighborhood of Nyarugenge - Ruliba, this
              4-bedroom property combines comfort and practicality. Featuring 2
              bathrooms, a spacious living and dining area, an outdoor kitchen
              sink, and a guard house, it’s ideal for family living. With two
              annex houses, an additional outdoor toilet and bathroom, a large
              water tank, and parking for 2-3 cars, this home is designed to
              meet all your needs.
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12">
        <div class="agent-contactUs-card">
          <div class="agent">
            <div>
              <div class="agent-image-container">
                <img
                  class="img-responsive"
                  src="../../assets/icons/agentt.png"
                  alt="image"
                />
              </div>
              <div class="agent-details">
                <h4 class="agent-name">Agent Karamaga Yvan</h4>
                <div><span>Office: </span>+250 788 908 082</div>
                <div><span>MObile: </span>+250 788 908 082</div>
                <div><span>WhatsApp: </span> +250 788 908 082</div>
                <div><span>Email: </span> glandsbrwanda&#64;gmail.com</div>
              </div>
            </div>
          </div>
          <div class="agent-cut">
            <div class="cta-section image-bg">
              <div class="call-to-action text-left">
                <div class="container">
                  <div class="brand-info contactUsTitle">
                    <div class="contact">Contact us</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="contactUs">
            <div class="contact-info">
              <div name="contact-form" method="post" action="#">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control control-1"
                    required="required"
                    placeholder="Name"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    required="required"
                    placeholder="Phone number"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="email"
                    class="form-control"
                    required="required"
                    placeholder="E-mail"
                  />
                </div>

                <div class="form-group">
                  <textarea
                    name="message"
                    id="message"
                    required="required"
                    class="form-control"
                    rows="7"
                    placeholder="Message"
                  ></textarea>
                </div>
              </div>
              <div class="submit-button">
                <button class="btn btn-primary">submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
