<div class="home-section image-bg">
  <div
    class="home-image"
    [ngStyle]="{ 'background-image': 'url(' + backgroundImage + ')' }"
  ></div>
  <div class="overlayy"></div>

  <div class="home-content">
    <div class="header-container">
      <div id="home-slider" class="">
        <div class="carousel-inner" role="listbox">
          <div [ngClass]="checkActive('FIRST')" class="item">
            <h2>Build Your <span>project</span> With US</h2>
            <a
              alt="contact us button"
              (click)="goContactUs()"
              class="btn btn-primary"
              >Contact Us</a
            >
          </div>
          <div [ngClass]="checkActive('SECOND')" class="item">
            <h2>Looking for a <span>Good</span> Excavator ?</h2>
            <a
              alt="contact us button"
              (click)="goContactUs()"
              class="btn btn-primary"
              >Contact Us</a
            >
          </div>
          <div [ngClass]="checkActive('THRID')" class="item">
            <h2><span>The Best</span> Surveyor For Your project</h2>
            <a
              alt="contact us button"
              (click)="goContactUs()"
              class="btn btn-primary"
              >Contact Us</a
            >
          </div>
        </div>
      </div>

      <div class="services">
        <div class="services-container">
          <div class="service-container">
            <div class="service crane-lifting image-bg">
              <div class="overlay"></div>
              <div class="image-box">
                <img
                  style="max-width: 100px; margin-top: 40px"
                  class="img-responsive"
                  src="../../assets/image/service/QS27ic01.svg"
                  alt="service equipment image"
                />
              </div>
              <div class="box-title machineTitle">
                <h3>equipment rental</h3>
                <p>
                  Top-tier construction equipment rentals for efficient,
                  successful projects.
                </p>
              </div>
            </div>
          </div>
          <div class="service-container">
            <div class="service house-plans image-bg">
              <div class="overlay"></div>
              <div class="image-box">
                <img
                  class="img-responsive"
                  style="max-width: 70px; position: relative; bottom: -30px"
                  src="../../assets/image/Zum6cZ01.svg"
                  alt="service construction image"
                />
              </div>
              <div class="box-title">
                <h3>Construction</h3>
                <p>
                  Trusted construction services for projects of every size and
                  scope.
                </p>
              </div>
            </div>
          </div>
          <div class="service-container">
            <div class="service house-plans image-bg">
              <div class="image-box">
                <img
                  class="img-responsive"
                  style="max-width: 100px; position: relative; bottom: 0px"
                  src="../../assets/image/Screenshot_from_2024-09-07_10-42-59-removebg-preview.png"
                  alt="service Land survey image"
                />
              </div>
              <div class="box-title">
                <h3>Land survey</h3>
                <p>
                  Ensures accurate representation of legal rights and
                  restrictions
                </p>
              </div>
            </div>
          </div>
          <div class="service-container">
            <div class="service home-plumbing image-bg">
              <div class="overlay"></div>
              <div class="image-box">
                <img
                  style="margin-top: 10px"
                  class="img-responsive"
                  src="../../assets/image/service/2.png"
                  alt="service real-estate image"
                />
              </div>
              <div class="box-title">
                <h3>Real Estate</h3>
                <p>
                  Compprehensive real estate services for buying, selling, and
                  renting.
                </p>
              </div>
            </div>
          </div>

          <div class="service-container">
            <div class="service house-plans image-bg">
              <div class="overlay"></div>
              <div class="image-box">
                <img
                  class="img-responsive"
                  style="max-width: 100px; position: relative; bottom: -30px"
                  src="../../assets/image/o0eRVW01.svg"
                  alt="service job image"
                />
              </div>
              <div class="box-title">
                <h3>Jobs</h3>
                <p>
                  Discover job opportunities and career support with our team.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- services -->
    </div>
    <!-- container -->
  </div>
  <!-- home content -->
</div>
<!-- home -->

<app-about-us />

<!-- features section -->

<app-our-service />

<!-- service section -->
<app-team />

<!-- portfolio section -->
<div class="work-process image-bg section-padding">
  <div class="overlay"></div>
  <div class="container">
    <div class="section-title text-center">
      <h1>Why Choose Us</h1>
      <h2>Our Promise and Values</h2>
    </div>
    <div class="process-section">
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <div class="work-proces left-content text-right">
            <div class="process-icon">
              <img
                class="img-responsive"
                src="../../assets/image/process/proces1.png"
                alt="testimonial image"
              />
            </div>
            <div class="process-info">
              <h4>Commitment to integrity</h4>
              <p>
                Our work is grounded in integrity and transparency, building
                strong, trustworth relationships with our clients through honest
                communication and reliable service.
              </p>
            </div>
          </div>

          <div class="work-proces left-content text-right">
            <div class="process-icon">
              <img
                class="img-responsive"
                src="../../assets/image/process/proces2.png"
                alt="testimonial image"
              />
            </div>
            <div class="process-info">
              <h4>Precision and Excellence</h4>
              <p>
                We are dedicated construction solutions with meticulous care and
                precision, ensuring every details meets our high standards of
                excellence.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <div class="work-proces">
            <div class="process-icon">
              <img
                class="img-responsive"
                src="../../assets/image/process/proces3.png"
                alt=" testimonial image"
              />
            </div>
            <div class="process-info">
              <h4>Comprehensive Expertise</h4>
              <p>
                From construction and real estate to equipment rentals and job
                placement, our Comprehensive range of services and deep industry
                knowledge ensure that we effectively meet all you needs under on
                roof.
              </p>
            </div>
          </div>
          <div class="work-proces">
            <div class="process-icon">
              <img
                class="img-responsive"
                src="../../assets/image/process/proces4.png"
                alt="testimonial image"
              />
            </div>
            <div class="process-info">
              <h4>High-Quality Solutions</h4>
              <p>
                we profide high-quality construction services that address you
                unique needs, aiming to create lasting value and exceed your
                expectations with every project.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- row -->
  </div>
  <!-- container -->
</div>
<!-- process -->

<div class="blog-section section-padding">
  <div class="container">
    <div class="section-title text-center">
      <h1>Blog & News</h1>
      <h2>Keep Updated</h2>
    </div>
    <div class="blog-content">
      <div class="row">
        <div
          *ngFor="let item of items"
          class="col-md-4 col-sm-6 card-blog"
          (click)="gotoBlogDetails(item.link)"
        >
          <div class="blog-post">
            <div class="entry-header">
              <div class="blog-image">
                <div class="entry-thumbnail">
                  <a class="blogImageCont"
                    ><img
                      class="img-responsive"
                      [src]="item.image"
                      alt="Blog Image"
                      id="blogImage"
                  /></a>
                </div>
                <div class="time">
                  <h2>07 <span>Aug</span></h2>
                </div>
              </div>
              <!-- blog-image -->
            </div>
            <div class="entry-post">
              <div class="entry-title">
                <h4>
                  <a alt="glands blog title">{{ item.title }}</a>
                </h4>
              </div>
              <div class="post-content">
                <div class="entry-summary">
                  <p>
                    {{ item.introduction }}
                  </p>
                  <div class="entry-meta">
                    <ul class="list-inline">
                      <li>
                        <a alt="glands blog writter"
                          ><i class="fa fa-user"></i>{{ item.author }}</a
                        >
                      </li>
                      <li>
                        <a alt="glands blog subject"
                          ><i class="fa fa-folder-o"></i>{{ item.subject }}</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <!-- entry-post -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-testimonial />
