<div class="breadcrumb-section image-bg">
  <div class="overlay"></div>
  <div class="breadcrumb-content container">
    <h1>Our Projects</h1>
    <ol class="breadcrumb">
      <li><a alt="glands home link">Home</a></li>
      <li class="active">Projects</li>
    </ol>
  </div>
</div>

<div class="recent-projects section-padding">
  <div class="container">
    <div id="projects">
      <div class="cbp-item project-content filter-one filter-five filter-two">
        <div class="project-title">
          <h3><a alt="glands project title">Seaside Connection</a></h3>
          <span>Clients: Dreamer</span>
        </div>
        <div class="project-item">
          <a href="../../assets/image/project/1.jpg" class="image-link"
            ><img
              class="img-responsive"
              src="../../assets/image/project/1.jpg"
              alt="glands project image"
          /></a>
        </div>
        <div class="project-info">
          <p>
            Mountain retreat with cozy cabins, infinity pools, and alpine views.
          </p>
        </div>
      </div>
      <div class="cbp-item project-content filter-three filter-two">
        <div class="project-title">
          <h3>
            <a alt="glands project title">Elysium Heights</a>
          </h3>
          <span>Clients: Asthetic</span>
        </div>
        <div class="project-item">
          <a href="../../assets/image/project/2.jpg" class="image-link"
            ><img
              class="img-responsive"
              src="../../assets/image/project/2.jpg"
              alt="glands project image"
          /></a>
        </div>
        <div class="project-info">
          <p>
            Mountain retreat with cozy cabins, infinity pools, and alpine views.
          </p>
        </div>
      </div>
      <div class="cbp-item project-content filter-one filter-four">
        <div class="project-title">
          <h3>
            <a alt="glands project title">Zenith Grand Hotel</a>
          </h3>
          <span>Clients: Martin</span>
        </div>
        <div class="project-item">
          <a href="../../assets/image/project/3.jpg" class="image-link"
            ><img
              class="img-responsive"
              src="../../assets/image/project/3.jpg"
              alt="glands project image"
          /></a>
        </div>
        <div class="project-info">
          <p>
            An ultra-modern hotel with opulent suites, fine dining, rooftop
            pool,
          </p>
        </div>
      </div>
      <div class="cbp-item project-content filter-five filter-two">
        <div class="project-title">
          <h3><a alt="glands project title">The Oasis Palace </a></h3>
          <span>Clients: Marbel</span>
        </div>
        <div class="project-item">
          <a href="../../assets/image/project/4.jpg" class="image-link"
            ><img
              class="img-responsive"
              src="../../assets/image/project/4.jpg"
              alt="glands project image"
          /></a>
        </div>
        <div class="project-info">
          <p>
            Mountain retreat with cozy cabins, infinity pools, and alpine views.
          </p>
        </div>
      </div>
      <div class="cbp-item project-content filter-two filter-three">
        <div class="project-title">
          <h3>
            <a alt="glands project title">Quantum Gridt</a>
          </h3>
          <span>Clients: Asthetic</span>
        </div>
        <div class="project-item">
          <a href="../../assets/image/project/2.jpg" class="image-link"
            ><img
              class="img-responsive"
              src="../../assets/image/project/5.jpg"
              alt="glands project image"
          /></a>
        </div>
        <div class="project-info">
          <p>
            Next-gen nuclear power plant with advanced safety and efficiency
            tech.
          </p>
        </div>
      </div>
      <div
        class="cbp-item project-content filter-five filter-four filter-one filter-three"
      >
        <div class="project-title">
          <h3>
            <a alt="glands project image">Aurora Sky Towers</a>
          </h3>
          <span>Clients: Martin</span>
        </div>
        <div class="project-item">
          <a href="../../assets/image/project/3.jpg" class="image-link"
            ><img
              class="img-responsive"
              src="../../assets/image/project/6.jpg"
              alt="glands project image"
          /></a>
        </div>
        <div class="project-info">
          <p>
            Mountain retreat with cozy cabins, infinity pools, and alpine views.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<app-testimonial />
