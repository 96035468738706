import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-about-us',
  standalone: true,
  imports: [],
  templateUrl: './aboutus.component.html',
  styleUrl: './aboutus.component.scss',
})
export class AboutuscComponent {
  constructor(private router: Router) {}
  goAboutUs() {
    this.router.navigate(['/about']);
  }
}
