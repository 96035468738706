import { Component } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { Router } from '@angular/router';
@Component({
  selector: 'app-our-service',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './ourservice.component.html',
  styleUrl: './ourservice.component.scss',
})
export class OurServiceComponent {
  constructor(private router: Router) {}
  currentBackgroundImage: string = '';

  onMouseEnter(service: string) {
    if (service === 'machine') {
      this.currentBackgroundImage = 'machineImage';
    } else if (service === 'land survey') {
      this.currentBackgroundImage = 'land-surveyImage'
    } else if (service === 'Real estate') {
      this.currentBackgroundImage = 'real-estateIMage'
    } else if (service === 'construction') {
      this.currentBackgroundImage ='constructionImage'
    } else if (service === 'Jobs') {
      this.currentBackgroundImage = 'jobsImage'
    }
  }
  onMouseLeave() {
    this.currentBackgroundImage = '';
  }

  changeRouter(url: string) {
    this.router.navigate([url]);
  }
}
