import { Injectable } from '@angular/core';
import { HttpClientService } from '../../http/http-client.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProjectsApiService {
  constructor(private http: HttpClientService) {}
  getProjects(): Observable<any> {
    return this.http.get('/project/projects');
  }
}
