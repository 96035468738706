import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { forEach } from 'lodash';
import { EnvironmentService } from '../environments/environment.service';
import { HttpMethod, HttpOptions, JSONBodyParams } from './http.types';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpClientService {
  private readonly headers = new HttpHeaders({
    Accept: 'application/json',
  });

  constructor(
    private readonly environment: EnvironmentService,
    private readonly http: HttpClient
  ) {}

  request<T>(
    method: HttpMethod,
    url: string,
    params?: JSONBodyParams,
    body?: any,
    isFile?: boolean
  ): Observable<T> {
    const fullUrl = this.getUrl(url);
    const options: HttpOptions = {
      headers: this.headers,
    };

    if (isFile) {
      console.log('====> am here', isFile);

      options.headers.append('responseType', 'blob');
    }

    if (params) {
      options.params = this.getParams(params);
    }

    if (body) {
      options.body = body;
    }

    return this.http
      .request<T>(method, fullUrl, options)
      .pipe(catchError(this.handleError));
  }

  private getUrl(path: string): string {
    const { apiUrl } = this.environment;

    return path.charAt(0) === '!'
      ? path.substr(1, path.length)
      : `${apiUrl}${path}`;
  }

  private getParams(params: any): HttpParams {
    let newParams = new HttpParams();

    forEach(params, (value, key) => {
      newParams = newParams.append('' + key, value);
    });

    return newParams;
  }

  get<T>(
    url: string,
    params: JSONBodyParams = {},
    isFile?: boolean
  ): Observable<T> {
    console.log(isFile);
    return this.request<T>('GET', url, params, undefined, isFile);
  }

  post<T>(url: string, body: JSONBodyParams = {}): Observable<T> {
    return this.request<T>('POST', url, undefined, body);
  }

  put<T>(url: string, body: JSONBodyParams = {}): Observable<T> {
    return this.request<T>('PUT', url, undefined, body);
  }

  patch<T>(url: string, params: JSONBodyParams = {}): Observable<T> {
    return this.request<T>('PATCH', url, params);
  }

  delete<T>(url: string, params: JSONBodyParams = {}): Observable<T> {
    return this.request<T>('DELETE', url, params);
  }

  // Centralized error handling
  private handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.error(errorMessage);
    return throwError(() => new Error(errorMessage));
  }
}
