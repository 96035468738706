<div class="breadcrumb-section image-bg">
  <div class="overlay"></div>
  <div class="breadcrumb-content container">
    <h1>Project Details</h1>
    <ol class="breadcrumb">
      <li><a alt="glands home link">Home</a></li>
      <li class="active">project Details</li>
    </ol>
  </div>
</div>
<!-- breadcrumb-section -->
