<header id="header">
  <div class="top-bar">
    <div class="container-size">
      <div class="contact-container">
        <div class="info-box email-box">
          <div>
            <i class="fa fa-envelope"></i>
          </div>
          <div aria-label="glandsb email" class="info-text">
            <h5>Email Address:</h5>
            <a aria-label="glandsb email">glandsbrwanda&#64;gmail.com</a>
          </div>
        </div>
        <div class="info-box">
          <div>
            <i class="fa fa-phone"></i>
          </div>

          <div aria-label="glandsb phone contact" class="info-text">
            <h5>Contact Number:</h5>
            <span>+250 788908082</span>
          </div>
        </div>
        <div class="info-box time-box">
          <div>
            <i class="fa fa-clock-o"></i>
          </div>
          <div aria-label="glandsb Opening time" class="info-text">
            <h5>Opening Time:</h5>
            <a alt="glandsb opning time">Mon-Sat: 9:00 - 18:00</a>
          </div>
        </div>
      </div>

      <div class="top-social pull-right">
        <ul class="list-inline">
          <li>
            <a aria-label="glandsb twitter link"
              ><i class="fa fa-twitter"></i
            ></a>
          </li>
          <li>
            <a aria-label="glandsb linkedin link"
              ><i class="fa fa-linkedin-square"></i
            ></a>
          </li>
          <li>
            <a aria-label="glandsb facebook link"
              ><i class="fa fa-facebook-square"></i
            ></a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/gsbrwanda/"
              aria-label="glandsb instagram link"
              ><i class="fa fa-instagram"></i
            ></a>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="main-menu-container" [ngClass]="{ 'main-header': false }">
    <div class="main-menu">
      <nav class="navbar navbar-default">
        <div class="container-size">
          <div class="navbar-header">
            <a
              aria-label="glandsb homepage link, glandsb logo"
              (click)="changRoutes('/')"
              ><img
                class="logo"
                src="../../../assets/image/logo.png"
                alt=" Glandsb Logo"
            /></a>
          </div>
          <div>
            <ul class="nav navbar-nav navbar-right">
              <li [ngClass]="checkActive('/')">
                <a aria-label="glandsb homepage link" (click)="changRoutes('/')"
                  >Home</a
                >
              </li>
              <li [ngClass]="checkActive('/service')">
                <a
                  aria-label="glandsb service link"
                  (click)="changRoutes('/service/Machine')"
                  >Services</a
                >
              </li>
              <!-- <li [ngClass]="checkActive('/project')">
                <a
                  aria-label="glandsb projects link"
                  (click)="changRoutes('/project')"
                  >Projects</a
                >
              </li> -->
              <li [ngClass]="checkActive('/about')">
                <a
                  aria-label="glandsb about link"
                  (click)="changRoutes('/about')"
                  >About us</a
                >
              </li>
              <li [ngClass]="checkActive('/blog')">
                <a
                  aria-label="glandsb blogs link"
                  (click)="changRoutes('/blog')"
                  >Blog</a
                >
              </li>
              <li [ngClass]="checkActive('/contactus')">
                <a
                  aria-label="glandsb Contact us page  link"
                  (click)="changRoutes('/contactus')"
                  >Contact us</a
                >
              </li>
            </ul>
          </div>
          <div (click)="sidebarVisible1 = true" class="navbar-button">
            <img
              src="../../../assets/icons/1904665_bar_lines_list_menu_nav_icon.svg"
              alt=""
            />
          </div>
        </div>
      </nav>
    </div>
  </div>

  <div
    class="main-menu-container-side"
    [ngClass]="{ 
      'sticky-header': stickHeader,
      'hidden-header': !stickHeader,
 }"
  >
    <div class="main-menu">
      <nav class="navbar navbar-default">
        <div class="container-size">
          <div class="navbar-header">
            <a (click)="changRoutes('/')"
              ><img
                class="logo"
                src="../../../assets/image/logo.png"
                alt="glandsb logo"
            /></a>
          </div>
          <div>
            <ul class="nav navbar-nav navbar-right">
              <li [ngClass]="checkActive('/')">
                <a alt="glandsb home" (click)="changRoutes('/')">Home</a>
              </li>
              <li [ngClass]="checkActive('/service')">
                <a
                  alt="glandsb services link"
                  (click)="changRoutes('/service/Machine')"
                  >Services</a
                >
              </li>
              <!-- <li [ngClass]="checkActive('/project')">
                <a alt="glandsb project link" (click)="changRoutes('/project')"
                  >Projects</a
                >
              </li> -->
              <li [ngClass]="checkActive('/about')">
                <a alt="glandsb about-us link" (click)="changRoutes('/about')"
                  >About us</a
                >
              </li>
              <li [ngClass]="checkActive('/blog')">
                <a alt="glandsb Blog link" (click)="changRoutes('/blog')"
                  >Blog</a
                >
              </li>
              <li [ngClass]="checkActive('/contactus')">
                <a
                  alt="glandsb contact-us link"
                  (click)="changRoutes('/contactus')"
                  >Contact us</a
                >
              </li>
            </ul>
          </div>
          <div (click)="sidebarVisible1 = true" class="navbar-button">
            <img
              src="../../../assets/icons/1904665_bar_lines_list_menu_nav_icon.svg"
              alt=""
            />
          </div>
        </div>
      </nav>
    </div>
  </div>

  <div class="card flex flex-wrap justify-content-center gap-2">
    <p-sidebar
      [(visible)]="sidebarVisible1"
      position="left"
      [style]="{ 'background-color': '#283949' }"
    >
      <div class="pagelinks">
        <div (click)="changRoutes('/')" [ngClass]="checkActive('/')">HOME</div>
        <div
          (click)="changRoutes('/service/Machine')"
          [ngClass]="checkActive('/service')"
        >
          SERVICE
        </div>
        <div
          (click)="changRoutes('/project')"
          [ngClass]="checkActive('/project')"
        >
          PROJECTS
        </div>
        <div (click)="changRoutes('/about')" [ngClass]="checkActive('/about')">
          ABOUT US
        </div>
        <div (click)="changRoutes('/blog')" [ngClass]="checkActive('/blog')">
          BLOG
        </div>
        <div
          (click)="changRoutes('/contactus')"
          [ngClass]="checkActive('/contactus')"
        >
          CONTACT US
        </div>
      </div>
    </p-sidebar>
  </div>
</header>
