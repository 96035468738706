import { Injectable } from '@angular/core';
import { environment } from './environment';
import { ContactDetails, Environment } from './environment.types';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService implements Environment {
  get production(): boolean {
    return environment.production;
  }

  get appName(): string {
    return environment.appName;
  }

  get apiUrl(): string {
    return environment.apiUrl;
  }

  get uniqueIdentifier(): string {
    return environment.uniqueIdentifier;
  }

  get userIdleTimeout(): number {
    return environment.userIdleTimeout;
  }

  get language(): string {
    return environment.language;
  }

  get contactDetails(): ContactDetails {
    return environment.contactDetails;
  }
}
