import { Component, OnInit } from '@angular/core';
import { TeamComponent } from '../../components/team/team.component';
import { TestimonialComponent } from '../../components/testimonial/testimonial.component';
import { AboutuscComponent } from '../../components/aboutus/aboutus.component';
import { OurServiceComponent } from '../../components/ourservice/ourservice.component';
import { trigger, style, animate, transition } from '@angular/animations';
import { SharedModule } from '../../shared/shared.module';
import { Router } from '@angular/router';

// Define the interface
export interface Property {
  image: string;
  location: string;
  info: string;
  price: string;
}

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    TeamComponent,
    TestimonialComponent,
    SharedModule,
    AboutuscComponent,
    OurServiceComponent,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent implements OnInit {
  constructor(private router: Router) {}
  imagey = 'assets/images/teamt.png';
  currentIndex = 0;
  intervalId: any;
  currentActive: string = 'FIRST';
  slides: Array<string> = ['FIRST', 'SECOND', 'THRID'];
  items: Array<any> = [
    {
      title: 'Top 10 Benefits Of Construction Equipment Telematics',
      introduction:
        'Equipment owners using telematics data are reaping benefits such as improved productivity and reduced operating costs. Here are the top 10 benefits of using telematics systems to monitor and control your heavy equipment',
      link: '66f91c58ea7ffe08cb1bcea0',
      subject: 'Heavy Equipment',
      author: 'Karamaga Yvan',
      image: '../../assets/image/construction.jpg',
    },
    {
      title: 'Contractors Should Be Embracing BIM Adoption',
      introduction:
        'Is your construction firm avoiding BIM? Reasons may include cost concerns, resource limitations, or comfort with manual takeoffs. However, this might lead to inefficiencies like costly rework, impacting your profits.',
      link: '66f91c58ea7ffe08cb1bcea1',
      subject: 'Operating Insights',
      author: 'Karamaga Yvan',
      image: '../../assets/image/BIM-e1466678486781-2.jpg',
    },
    {
      title: 'When to Capitalize Construction Loan Interest',
      introduction:
        'Capitalizing construction loan interest boosts asset value, optimizes costs, and enhances cash flow. Learn when and how to capitalize, its financial benefits, and tools like Building Radar for smarter project planning and savings.',
      link: '66f91c58ea7ffe08cb1bcea3',
      subject: 'Construction',
      author: 'Karamaga Yvan',
      image: '../../assets/image/Construction-Loan-Interest.jpg',
    },
  ];

  images = [
    '../../assets/image/11.png',
    '../../assets/image/bg/2.jpg',
    '../../assets/image/01-0535.png',
    '../../assets/icons/63cf57f08f4762b73a0d6b14_AdobeStock_172840226-p-1080.webp',
  ];

  currentImageIndex = 0;
  nextImageIndex = 1;
  backgroundImage = '';
  fadeIn = false;

  startSlideshow() {
    this.fadeIn = !this.fadeIn;
    setInterval(() => {
      this.nextSlide();
      setTimeout(() => {
        this.currentImageIndex =
          (this.currentImageIndex + 1) % this.images.length;
        this.backgroundImage = this.images[this.currentImageIndex];
      }, 750);
    }, 3000);
  }

  ngOnInit() {
    this.startSlideshow();
    this.backgroundImage = this.images[this.currentImageIndex];
  }

  gotoBlogDetails(link: string) {
    this.router.navigate([`blog/${link}`]);
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
  }

  nextSlide() {
    this.currentActive = this.slides[this.currentIndex];
    this.currentIndex++;
    if (this.currentIndex === 3) {
      this.currentIndex = 0;
    }
  }
  checkActive(slide: string) {
    if (slide === this.currentActive) {
      return 'active';
    } else {
      return '';
    }
  }
  goContactUs() {
    this.router.navigate(['/contactus']);
  }
}
