<div class="breadcrumb-section image-bg">
  <div class="overlay"></div>
  <div class="breadcrumb-content container">
    <h1>About Us</h1>
    <ol class="breadcrumb">
      <li><a alt="glandsb home link">Home</a></li>
      <li class="active">About Us</li>
    </ol>
  </div>
</div>

<div class="about-us-section section-padding">
  <div class="container">
    <div class="section-title text-center">
      <h1>What We Do</h1>
    </div>
    <div class="row d-flex">
      <div class="col-md-6">
        <div class="about-section-content">
          <h2>Construction Equipment Rentals</h2>
          <div class="col-md-6 sm-image">
            <div class="about-us-image">
              <img
                class="img-responsive"
                style="border-radius: 6px; max-height: 410px"
                src="../../assets/icons/heavy-construction-equipment-types.jpg"
                alt=""
              />
            </div>
          </div>
          <p>
            Our equipment rental service supplies contractors with high-quality
            machinery that meets the demands of modern construction projects. We
            offer an extensive range of equipment, from heavy-duty machines to
            specialized tools, all rigorously maintained for reliability. With
            flexible rental terms and full support, we make it convenient to
            access the right tools at the right time, enhancing project
            efficiency and keeping costs manageable. Our service ensures that
            every project can proceed without interruption, equipped with the
            latest and most dependable equipment.
          </p>
        </div>
      </div>
      <div class="col-md-6 lg-image">
        <div class="about-us-image">
          <img
            class="img-responsive"
            style="border-radius: 6px; max-height: 290px"
            src="../../assets/icons/heavy-construction-equipment-types.jpg"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 lg-image">
        <div class="about-us-image">
          <img
            class="img-responsive"
            style="border-radius: 6px; max-height: 290px"
            src="../../assets/image/gREENpARK-kAREN.jpg"
            alt=""
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="about-section-content">
          <h2>Real Estate</h2>

          <div class="col-md-6 sm-image">
            <div class="about-us-image">
              <img
                class="img-responsive"
                style="border-radius: 6px; max-height: 290px"
                src="../../assets/image/gREENpARK-kAREN.jpg"
                alt=""
              />
            </div>
          </div>
          <p>
            Our real estate development services focus on maximizing the value
            and potential of each property. We conduct detailed site analysis,
            selecting strategic locations and planning sustainable developments
            that enhance communities and provide long-term value. From
            acquisition to final development, our team manages each step with a
            focus on quality and efficient resource allocation. We specialize in
            creating spaces that are as functional as they are innovative,
            whether for residential, commercial, or mixed-use purposes. Our
            approach is designed to yield returns for investors while adding
            lasting value to the area.
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="about-section-content">
          <h2>Job Placement and Recruitment</h2>
          <div class="col-md-6 sm-image">
            <div class="about-us-image">
              <img
                class="img-responsive"
                style="border-radius: 6px; max-height: 290px"
                src="../../assets/icons/eng.png"
                alt=""
              />
            </div>
          </div>
          <p>
            We connect qualified professionals with industry roles that meet
            specific project demands, helping clients build reliable, skilled
            teams. Through a thorough vetting process, we match candidates who
            meet technical requirements and align with the project’s goals. From
            short-term placements to long-term staffing, we adapt our
            recruitment solutions to meet the needs of each project. Our goal is
            to support projects with a workforce that’s ready to perform,
            ensuring smooth, successful execution. Clients can depend on us for
            a skilled workforce that drives productivity and project success.
          </p>
        </div>
      </div>
      <div class="col-md-6 lg-image">
        <div class="about-us-image">
          <img
            class="img-responsive"
            style="border-radius: 6px; max-height: 290px"
            src="../../assets/icons/eng.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 lg-image">
        <div class="about-us-image">
          <img
            class="img-responsive"
            style="border-radius: 6px; max-height: 290px"
            src="../../assets/image/Screenshot from 2024-09-07 11-44-02.png"
            alt=""
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="about-section-content">
          <h2>Topographic Surveying</h2>
          <div class="col-md-6 sm-image">
            <div class="about-us-image">
              <img
                class="img-responsive"
                style="border-radius: 6px; max-height: 290px"
                src="../../assets/image/Screenshot from 2024-09-07 11-44-02.png"
                alt=""
              />
            </div>
          </div>
          <p>
            Precision is key in topographic surveying, and we deliver it through
            advanced technology and experienced professionals. Our surveying
            services provide essential data on terrain, boundaries, and
            elevations, forming the backbone of informed project planning. We
            support construction and development by identifying critical details
            of the land, from initial assessments to final validations. Each
            survey is conducted to deliver precise, reliable data that guides
            safe, efficient construction practices. With our expertise, clients
            receive the accurate foundation they need to begin any project with
            confidence.
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="about-section-content">
          <h2>Construction</h2>
          <div class="col-md-6 sm-image">
            <div class="about-us-image">
              <img
                class="img-responsive"
                style="border-radius: 6px; max-height: 290px"
                src="../../assets/image/project/8.jpg"
                alt=""
              />
            </div>
          </div>
          <p>
            We offer comprehensive construction solutions, managing all aspects
            from planning and design to execution and final handover. Our team
            builds durable, functional structures for residential, commercial,
            and industrial projects with an emphasis on quality and safety. By
            adhering to rigorous timelines, budget controls, and quality
            standards, we ensure that every project aligns with client
            expectations and industry benchmarks. From foundation to finishing,
            our projects are designed to meet the demands of modern
            infrastructure. We build to last, providing structures that are both
            practical and resilient.
          </p>
        </div>
      </div>
      <div class="col-md-6 lg-image">
        <div class="about-us-image">
          <img
            class="img-responsive"
            style="border-radius: 6px; max-height: 290px"
            src="../../assets/image/project/8.jpg"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</div>

<app-testimonial />
