<div class="breadcrumb-section image-bg">
  <div class="overlay"></div>
  <div class="breadcrumb-content container">
    <h1>Our Blog</h1>
    <ol class="breadcrumb">
      <li (click)="changRoutes('/')"><a alt="glandsb home link">Home</a></li>
      <li class="active">Blog</li>
    </ol>
  </div>
</div>
<!-- breadcrumb-section -->

<div class="blog-section section-padding">
  <div class="blog-container">
    <div class="section-title text-center">
      <h1>Blog & News</h1>
      <h2>Keep Updated</h2>
    </div>
    <div class="blog-content container">
      <div class="row">
        <div
          *ngFor="let item of items"
          class="col-md-4 col-sm-6 card-blog"
          (click)="gotoBlogDetails(item.link)"
        >
          <div class="blog-post">
            <div class="entry-header">
              <div class="blog-image">
                <div class="entry-thumbnail">
                  <a
                    ><img
                      class="img-responsive"
                      [src]="item.image"
                      alt="glandsb Blog Image"
                      id="blogImage"
                  /></a>
                </div>
                <div class="time">
                  <h2>07 <span>Aug</span></h2>
                </div>
              </div>
              <!-- blog-image -->
            </div>
            <div class="entry-post">
              <div class="entry-title">
                <h4>
                  <a alt="glandsb blog title">{{ item.title }}</a>
                </h4>
              </div>
              <div class="post-content">
                <div class="entry-summary">
                  <p>
                    {{ item.introduction }}
                  </p>
                  <div class="entry-meta">
                    <ul class="list-inline">
                      <li>
                        <a alt="glandsb blog author"
                          ><i class="fa fa-user"></i>{{ item.author }}</a
                        >
                      </li>
                      <li>
                        <a alt="glandsb blog subject"
                          ><i class="fa fa-folder-o"></i>{{ item.subject }}</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <!-- entry-post -->
          </div>
        </div>
      </div>
      <!-- row -->
    </div>
    <!-- blog content -->
    <!-- <div class="pagination-section text-center">
      <ul class="pagination">
        <li>
          <a  aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>
        <li class="active"><a >1</a></li>
        <li><a >2</a></li>
        <li><a >3</a></li>
        <li><a >4</a></li>
        <li><a >5</a></li>
        <li>
          <a  aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
      </ul>
    </div> -->
    <!-- pagination-section -->
  </div>
</div>
