import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { CardModule } from 'primeng/card';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MessageModule } from 'primeng/message';
import { MenubarModule } from 'primeng/menubar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { DividerModule } from 'primeng/divider';
import { SliderModule } from 'primeng/slider';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DropdownModule } from 'primeng/dropdown';
import { RatingModule } from 'primeng/rating';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { SidebarModule } from 'primeng/sidebar';



const imports: any[] = [
  CommonModule,
  RouterModule,
  AngularSvgIconModule.forRoot(),
  ReactiveFormsModule,
  FormsModule,
  ToastModule,
  CardModule,
  ButtonModule,
  RippleModule,
  ProgressSpinnerModule,
  MessageModule,
  MenubarModule,
  ConfirmDialogModule,
  DynamicDialogModule,
  DialogModule,
  InputTextModule,
  DividerModule,
  SliderModule,
  InputTextareaModule,
  CalendarModule,
  CheckboxModule,
  AutoCompleteModule,
  DropdownModule,
  RatingModule,
  InputSwitchModule,
  InputGroupModule,
  InputGroupAddonModule,
  CarouselModule.forRoot(),
  SidebarModule
];

@NgModule({
  imports: [RouterModule],
  exports: [...imports],
  providers: [ConfirmationService, DialogService, DecimalPipe],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
    };
  }
}
