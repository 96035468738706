import { Component } from '@angular/core';
import { TestimonialComponent } from '../../components/testimonial/testimonial.component';
import { TeamComponent } from '../../components/team/team.component';

@Component({
  selector: 'app-about',
  standalone: true,
  imports: [TestimonialComponent, TeamComponent],
  templateUrl: './about.component.html',
  styleUrl: './about.component.scss',
})
export class AboutComponent {}
