<div class="breadcrumb-section image-bg">
  <div class="overlay"></div>
  <div class="breadcrumb-content container">
    <h1>Blog details</h1>
    <ol class="breadcrumb">
      <li alt="glandsb home link" (click)="changRoutes('/')"><a>Blog</a></li>
      <li class="active">Blog</li>
      <li class="active">Blog detail</li>
    </ol>
  </div>
</div>
<div class="blog-details">
  <div class="container">
    <div class="blog-content">
      <div class="blog-image">
        <div class="entry-thumbnail">
          <img
            class="img-responsive"
            src="../../assets/image/BIM-e1466678486781-2.jpg"
            alt="Blog Image"
          />
        </div>
        <div class="time">
          <h2>07 <span>Aug</span></h2>
        </div>
      </div>
      <!-- blog-image -->
      <div class="entry-title">
        <h3>Contractors Should Be Embracing BIM Adoption</h3>
      </div>
      <div class="entry-meta">
        <ul class="list-inline">
          <li>
            <a alt="glandsb blog author"
              ><i class="fa fa-user"></i>Karamaga Yvan</a
            >
          </li>
          <li>
            <a alt="glandsb blog subject"
              ><i class="fa fa-folder-o"></i>Operating Insights</a
            >
          </li>
        </ul>
      </div>
      <div class="post-content">
        <p>
          Is your construction company still not using Building Information
          Modeling (BIM)? If your firm hasn’t adopted BIM yet I’m sure there’s a
          valid reason. Perhaps you think it’s too expensive or you don’t have
          the resources to implement adoption. Maybe your estimators love
          spending countless hours doing manual takeoffs from plans and specs
          when preparing bids. It could be you enjoy doing costly rework that
          eats away at your profits.
        </p>
        <p>
          Whatever your reasons may be for not using BIM, it’s high time you
          reconsider that decision. BIM use is not a fad that will fade off into
          obscurity like flagpole sitting or swallowing live goldfish. It’s more
          than just a software tool and it’s more than just a 3D model, although
          software is necessary to create and work with BIM models. It’s not
          going to disappear one day like the Betamax or, more recently, the HD
          DVD. If anything, emerging technologies like augmented reality and
          virtual reality will be incorporated to enhance and improve the
          experience of working with BIM.
        </p>

        <blockquote>
          <i class="fa fa-quote-left"></i>
          Building Information Modeling (BIM) is a digital representation of
          physical and functional characteristics of a facility. A BIM is a
          shared knowledge resource for information about a facility forming a
          reliable basis for decisions during its lifecycle; defined as existing
          from earliest conception to demolition.
        </blockquote>
        <p>
          Taking it a step further, BIM is a process that incorporates those
          digital representations and facilitates better collaboration among all
          stakeholders on a project. This can lead to better design and
          construction of buildings.
        </p>

        <p>
          If you are a general contractor or trade contractor, here are the top
          reasons your company should be using BIM:
        </p>
        <h4 class="entry-title">Better Visualization</h4>
        <p>
          Having a digital representation of a project creates better
          visualization and provides greater insight into the details and design
          of a project to all stakeholders. BIM helps contractors better
          understand the scope of a project and clearly interpret the design
          intent in ways that just aren’t possible with 2D drawings.
        </p>

        <h4 class="entry-title">Submitting Better Bids</h4>
        <p>
          Because BIM models are created with objects, which consist of detailed
          information and the geometry representing its physical appearance, it
          is possible to generate accurate quantities of all building
          components. This also makes it easier to verify quantities and quotes
          provided by subcontractors.
        </p>

        <h4 class="entry-title">Avoiding Costly Rework</h4>
        <p>
          BIM can automate clash detection by comparing the different models and
          alerting you when objects are occupying the same space, like an
          electrical conduit running through a pipe. When schedules are added to
          the BIM model it can also determine if work is scheduled out of
          sequence. Addressing and correcting all clashes during the design
          phase or before construction begins can lead to a huge reduction, and
          possibly even elimination, of change orders.
        </p>
        <h4 class="entry-title">Improved Collaboration & Communication</h4>
        <p>
          Good collaboration and communication are key to any successful
          construction project. Changes to the BIM model occur in real time so
          any changes or updates to the model are instantly communicated to all
          team members when they access the model. Everyone is working with the
          most up-to-date information at all times. Because the schedule can be
          simulated, a visual representation of the construction process allows
          team members to plan out each phase of construction.
        </p>
        <p>
          BIM has been proven to decrease project delivery time and keep
          projects under budget. Other benefits include being able to easily
          identify potential hazards to create more effective safety plans and
          enhance lean construction practices.
        </p>
        <p>
          BIM is here to stay and the sooner the AEC industry as a whole adopts
          its use, the better.
        </p>
        <br />
      </div>
      <div class="share-social">
        <ul class="social">
          <li>Share:</li>
          <li>
            <a alt="glandsb facebook"><i class="fa fa-rss"></i></a>
          </li>
          <li>
            <a alt="glandsb facebook"><i class="fa fa-facebook"></i></a>
          </li>
          <li>
            <a alt="glandsb instagram"><i class="fa fa-tumblr"></i></a>
          </li>
          <li>
            <a alt="glandsb pinterest"><i class="fa fa-pinterest-p"></i></a>
          </li>
          <li>
            <a alt="glandsb flickr"><i class="fa fa-flickr"></i></a>
          </li>
          <li>
            <a alt="glandsb vimeo"><i class="fa fa-vimeo"></i></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
