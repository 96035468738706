<div class="cta-section image-bg">
  <div class="call-to-action text-left">
    <div class="container">
      <div class="brand-info">
        <h3>Direct Phone number or Whatsapp: +250 788 908 082</h3>
        <a
          alt="glandsb contact-us router button"
          (click)="changeRoute('contactus')"
          class="btn btn-primary"
          href="https://www.glandsb.com/contactus"
          >Contact Us</a
        >
      </div>
    </div>
  </div>
</div>

<footer class="footer">
  <div class="footer-section">
    <div class="intro-section">
      <div class="contact-widget">
        <h3>Gland standard Builders</h3>
        <p>
          Inspired by the vital role glands play in the human body as regulators
          of essential functions, we are dedicated to achieving precision and
          excellence in every project. Just as these organs maintain balance and
          harmony, we ensure meticulous care and unwavering integrity in our
          work.
        </p>
      </div>
    </div>
    <div class="business-section">
      <div class="contact-widget">
        <h3>Business Hours</h3>
        <p>
          Our support available to help you 24 hours a day, seven days a week.
        </p>
        <p>Monday - Thursday &#64; 09.00 - 17.30</p>
        <p>Friday & Saturday &#64; 10.00 - 16.00</p>
        <p>Sunday - <span> Closed </span></p>
      </div>
    </div>
    <div class="links-section">
      <div class="contact-widget">
        <h3>Quick Links</h3>
        <ul>
          <li>
            <a
              alt="glandsb about-us router button"
              (click)="changeRoute('about')"
              >About Us</a
            >
          </li>
          <li>
            <a
              alt="glandsb projects router button"
              (click)="changeRoute('project')"
              >Projects</a
            >
          </li>
          <li>
            <a
              alt="glandsb services router button"
              (click)="changeRoute('service')"
              >Services</a
            >
          </li>
          <li>
            <a alt="glandsb Blog router button" (click)="changeRoute('blog')"
              >Blog</a
            >
          </li>
          <li>
            <a
              alt="glandsb contact-us router button"
              (click)="changeRoute('contactus')"
              >Contact us</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="office-section">
      <div class="contact-widget">
        <h3>Our Office</h3>
        <address>
          <ul>
            <li><span class="address">Address:</span>kigali-Rwanda</li>
            <li><span>Phone: </span>+250 788908082</li>
            <li>
              <span>Email: </span
              ><a alt="glandsb  Email">glandsbrwanda&#64;gmail.com</a>
            </li>
          </ul>
        </address>
        <ul class="footer-social list-inline">
          <li>
            <a alt="glandsb twitter link"><i class="fa fa-twitter"></i></a>
          </li>
          <li>
            <a alt="glandsb linkedin link"
              ><i class="fa fa-linkedin-square"></i
            ></a>
          </li>
          <li>
            <a alt="glandsb facebook link"
              ><i class="fa fa-facebook-square"></i
            ></a>
          </li>
          <li>
            <a
              alt="glandsb instagram link"
              href="https://www.instagram.com/gsbrwanda/"
              ><i class="fa fa-instagram"></i
            ></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="sub-section">
      <div class="contact-widget">
        <h3>Subscribe</h3>
        <p>Subscribe to get latest property, blog news from us</p>
        <div class="subscribe-form">
          <input
            type="email"
            [(ngModel)]="email"
            placeholder="example@gmail.com"
          />
          <button (click)="subscribe()" class="subscribe-btn">
            {{ label }}
          </button>
        </div>
      </div>
    </div>
  </div>
</footer>
