import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
@Component({
  selector: 'app-contact-us',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './job-apply.component.html',
  styleUrl: './job-apply.component.scss',
})
export class JobApplyComponent {
  constructor(private router: Router) {}
  name: string = '';
  subject: string = '';
  email: string = '';
  message: string = '';
  labelHolder: string = 'Send Now';
  fileName: string = '';

  changRoutes(link: string) {
    this.router.navigate([link]);
  }

  wait(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async sendMessage() {
    this.labelHolder = 'SEND...';
    await this.wait(3000);
    this.name = '';
    this.subject = '';
    this.email = '';
    this.message = '';
    this.labelHolder = 'Sent';
    await this.wait(1000);
    this.labelHolder = 'Send Now';
  }
  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    const files = input.files;
    if (files && files.length > 0) {
      console.log('File selected:', files[0].name);
      this.fileName = files[0].name;
    }
  }
  deleteFile() {
    this.fileName = '';
  }
}
