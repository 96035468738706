import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { ServiceMachineApi } from './servvice-api.service';
import { EnvironmentService } from '../../environments/environment.service';

@Component({
  selector: 'app-contact-us',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './service-contact-us.component.html',
  styleUrl: './service-contact-us.component.scss',
})
export class ServiceContactUsComponent implements OnInit  {
  constructor(
    private readonly serviceApi: ServiceMachineApi,
    private readonly environment: EnvironmentService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
  
  
  }
  name: string = '';
  subject: string = '';
  email: string = '';
  message: string = '';
  labelHolder: string = 'Send Now';
  appUrl: string = '';
  machineId: any
  data: any

  ngOnInit(): void {
    this.appUrl = this.environment.apiUrl;
    this.machineId = this.activatedRoute.snapshot.paramMap.get('id')
    this.serviceApi.getMachineById(this.machineId).subscribe((res)=>{
    this.data= res.data
    console.log('===>', this.data)
    })
  }

  changRoutes(link: string) {
    this.router.navigate([link]);
  }

  wait(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async sendMessage() {
    this.labelHolder = 'SEND...';
    await this.wait(3000);
    this.name = '';
    this.subject = '';
    this.email = '';
    this.message = '';
    this.labelHolder = 'Sent';
    await this.wait(1000);
    this.labelHolder = 'Send Now';
  }
}
